<template>
  <v-card flat outlined class="transparent">
    <v-card-title>
      <h3>فوج الحماية</h3>
    </v-card-title>
    <v-card-subtitle> قم بتحديد الذين حضرو بالضغط عليه </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="filters.Data"
          :label="$t('bhth')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <Loading v-if="$global.state.loading" />
      <div v-else>
        <v-data-table
          :headers="headers()"
          hide-default-footer
          no-data-text="لا يوجد بيانات"
          :items="tableItems"
          elevation="1"
        >
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "../../../components/Loading.vue";
export default {
  data() {
    return {
      items: [],
      tableItems: [],
      field: {},
      filters: {
        Data: "",
        Sort: "desc",
        PageSize: 1090,
      },
    };
  },
  methods: {
    headers() {
      let headers = [];
      for (let i = 0; i < this.items.length; i++) {
        if (
          headers.filter((v) => v.value == this.items[i].fieldChild.id)
            .length == 0
        ) {
          headers.push({
            text: this.items[i].fieldChild.field.name,
            value: this.items[i].fieldChild.id,
          });
        }
      }
      return headers;
    },
    async getItems() {
      this.$global.state.loading = true;
      let GroupIds = [];
      if (this.$store.state.user.user.userGroups.length > 0) {
        await this.$store.state.user.user.userGroups.forEach((element) => {
          GroupIds.push(element.groupId);
        });
      }
      let url = "/orderDataChild?";
      for (let j = 0; j < GroupIds.length; j++) {
        url = url + `&GroupIds=${GroupIds[j]}`;
      }
      this.items = await this.$http.get(url, { params: this.filters });
      this.items = this.items.data.result;
      this.items.sort((a, b) => a.row - b.row);
      this.items.sort((a, b) => a.fieldChild.sequence - b.fieldChild.sequence);
      for (let i = 0; i < this.items.length; i++) {
        if (
          this.tableItems.filter((v) => v.row == this.items[i].row).length == 0
        ) {
          this.tableItems.push({
            row: this.items[i].row,
          });
        }
        this.tableItems.find((v) => v.row == this.items[i].row)[
          this.items[i].fieldChild.id
        ] = this.items[i].data;
      }
      console.log(this.tableItems);
      this.$global.state.loading = false;
    },
  },
  created() {
    this.getItems();
  },
  components: { Loading },
};
</script>
